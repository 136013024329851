export default [
  {
    header: 'Startup',
  },
  {
    title: 'Applications',
    route: 'applications',
    icon: 'SettingsIcon',
  },
  {
    title: 'Apply Now',
    route: 'apply-now',
    icon: 'SettingsIcon',
  },
  {
    title: 'Program',
    route: 'program',
    icon: 'SettingsIcon',
  },
]

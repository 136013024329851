/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
// import dashboard from './dashboard'
// import appsAndPages from './apps-and-pages'
// import others from './others'
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'
// import resources from './resources'
// import events from './events'
// import teams from './teams'
// import startups from './startups'
// import tickets from './tickets'
// import finances from './finances'
// import fundings from './fundings'
// import diligence from './duediligence'
// import networks from './networks'
import programs from './programs'
import startup from './start-up'

// Array of sections
// export default [...dashboard, ...programs, ...diligence, ...fundings, ...events, ...startups, ...networks, ...teams, ...finances, ...resources, ...tickets, ...startup, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
export default [...programs, ...startup]

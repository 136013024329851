export default [
  {
    header: 'Programs',
  },
  {
    title: 'Manage Programs',
    route: 'manage-programs',
    icon: 'SettingsIcon',
  },
  {
    title: 'Add Program',
    route: 'add-programs',
    icon: 'PlusIcon',
  },
]
